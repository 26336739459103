import React, { useState, useEffect } from "react";
import { Button, Col, Container, Image, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./BusinessPage.css";
import image1 from "../images/image1.jpeg";
import vjLogo from "../images/vjLogo.jpg";
// import { BsChevronDown, BsChevronUp } from "react-icons/bs";

function BusinessPage() {
  const [info, setInfo] = useState([]);
  const [tableData, setTableData] = useState([]);
  const user = useSelector((state) => state.authentication.user);
  const url = useParams();

  const fetchData = async () => {
    const response = await axios.get(`/api/user/${url.id}`);
    setInfo(response.data);
    setTableData(response.data.products);
    console.log(response.data);
    // console.log(response.data.products);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const requestSort = (key) => {
    let direction = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "desc",
  });
  const sortedData = tableData.sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    }
    if (sortConfig.direction === "desc") {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
    return 0;
  });

  return (
    <div>
      <Container>
        <Row className="tableRow">
          <div className="businessTop">
            <Image height="80px" src={info.logo} />
            <h1 className="businessName">{info.companyName}</h1>
          </div>
          <Row>
            <Col md={12}>
              <h3>{info.category}</h3>
              <div>
                <a href={`tel:${info.contactNumber}`}>
                  <Button
                    style={{ borderRadius: "100px" }}
                    className="btn--four"
                  >
                    <i className="fa-solid fa-phone" />
                  </Button>
                </a>
                <a target="_blank" href={`mailto:${info.email}`}>
                  <Button
                    style={{ borderRadius: "100px", margin: "0 5px" }}
                    className="btn--four"
                  >
                    <i className="fa-solid fa-envelope" />
                  </Button>
                </a>
                <a
                  target="_blank"
                  href={`https://wa.me/${info.whatsappNumber}/`}
                >
                  <Button
                    style={{ borderRadius: "100px" }}
                    className="btn--four"
                  >
                    <i className="fa-brands fa-whatsapp" />
                  </Button>
                </a>
                {/* <Button onClick={downloadCatalogue} /> */}
              </div>
              <p className="businessText">{info.aboutCompany}</p>
            </Col>
          </Row>
        </Row>
        <Row className="tableRow">
          <h2 className="productsHead">Products and Services</h2>
          <table className="productTable">
            <thead>
              <tr className="productTableHead">
                <SortableTh
                  label="S. No."
                  sortKey="id"
                  sortConfig={sortConfig}
                  requestSort={requestSort}
                  styles={{ width: "5%" }}
                />
                <SortableTh
                  label="Name"
                  sortKey="name"
                  sortConfig={sortConfig}
                  requestSort={requestSort}
                  styles={{ width: "20%" }}
                />
                <SortableTh
                  label={info.field1}
                  sortKey="field1Val"
                  sortConfig={sortConfig}
                  requestSort={requestSort}
                  styles={{ width: "20%" }}
                />
                {(() => {
                  if (info.field2 !== null) {
                    return (
                      <SortableTh
                        label={info.field2}
                        sortKey="field2Val"
                        sortConfig={sortConfig}
                        requestSort={requestSort}
                        styles={{ width: "20%" }}
                      />
                    );
                  }
                })()}
                {(() => {
                  if (info.field3 !== null) {
                    return (
                      <SortableTh
                        label={info.field3}
                        sortKey="field3Val"
                        sortConfig={sortConfig}
                        requestSort={requestSort}
                        styles={{ width: "20%" }}
                      />
                    );
                  }
                })()}
                <SortableTh
                  label="Image"
                  sortKey="image"
                  sortConfig={sortConfig}
                  requestSort={requestSort}
                  styles={{ width: "25%" }}
                />
              </tr>
            </thead>
          </table>
          <div className="table-container">
            <table className="productTable">
              <tbody className="scrollable-tbody">
                {tableData.map((row, index) => (
                  <tr key={row.id} className="productTableRow">
                    <td style={{ width: "5%" }}>{index + 1}</td>
                    <td style={{ width: "20%" }}>{row.name}</td>
                    <td style={{ width: "20%" }}>{row.field1Val}</td>
                    {(() => {
                      if (info.field2 !== null) {
                        return (
                          <td style={{ width: "20%" }}>{row.field2Val}</td>
                        );
                      }
                    })()}
                    {(() => {
                      if (info.field3 !== null) {
                        return (
                          <td style={{ width: "20%" }}>{row.field3Val}</td>
                        );
                      }
                    })()}
                    <td style={{ width: "25%" }}>
                      <Image
                        className="productTableImage"
                        src={row.photo}
                        alt={row.name}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
}

const SortableTh = ({ label, sortKey, sortConfig, requestSort, styles }) => {
  const isSorted = sortConfig.key === sortKey;
  const arrowIcon = isSorted
    ? sortConfig.direction === "asc"
      ? "^"
      : "^"
    : null;

  return (
    <th onClick={() => requestSort(sortKey)} style={styles}>
      <div>
        {label} {arrowIcon}
      </div>
    </th>
  );
};

export default BusinessPage;
