import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
  Modal,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import "./ManageProducts.css";

function ManageProducts() {
  const user = useSelector((state) => state.authentication.user);

  const addEvent = () => {
    if (products.length < 150) {
      setproducts([
        ...products,
        { name: "", field1: "", field2: "", field3: "" },
      ]);
    }
  };

  const [deleteModalshow, setDeleteModalShow] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleDeleteButton = (product) => {
    console.log(product);
    setSelectedProduct(product);
    setDeleteModalShow(true);
  };

  const handleModalClose = () => setDeleteModalShow(false);

  async function handleDelete(id) {
    console.log(id);
    await axios({
      method: "delete",
      url: `/api/product/${id}/`,
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        alert("Error, Please try again after some time");
      });
  }

  const handleProductChange = (index, field, value) => {
    setproducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      updatedProducts[index] = {
        ...updatedProducts[index],
        [field]: value,
      };
      return updatedProducts;
    });
  };

  const [editedPic, setEditedPic] = useState(null);
  const handleProductPicChange = (index, file) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

    if (file && file.size > maxSizeInBytes) {
      // The file size exceeds the limit, so you can display an error message or take some action.
      alert(
        "File size exceeds the maximum allowed size (2MB). Please choose a smaller file."
      );
      // Set the value of the file input to an empty string if no file is selected
      const fileInput = document.getElementsByName(`productPic${index + 1}`)[0];
      fileInput.value = "";
      setEditedPic(null);
    } else {
      setEditedPic(file);
    }
  };

  const [isSubmitting, setisSubmitting] = useState(false);
  const EditProduct = async (id) => {
    setisSubmitting(true);
    const productToUpdate = products.find((product) => product.id === id);

    if (!productToUpdate) {
      console.error("Product not found");
      setisSubmitting(false);
      return;
    }

    console.log(editedPic);

    // Check if the "photo" field contains a file
    const formData = new FormData();
    formData.append("name", productToUpdate.name);
    formData.append("field1Val", productToUpdate.field1Val);
    formData.append("field2Val", productToUpdate.field2Val);
    formData.append("field3Val", productToUpdate.field3Val);
    if (editedPic !== null) {
      formData.append("photo", editedPic);
    }

    await axios({
      method: "put",
      url: `/api/product/${id}/`,
      data: formData,
    })
      .then(function (response) {
        window.location.reload();
      })
      .catch(function (error) {
        console.error(error);
      });

    setisSubmitting(false);
  };

  const [products, setproducts] = useState([]);
  const [userData, setuserData] = useState("");

  const [field1, setfield1] = useState("");
  const [field2, setfield2] = useState("");
  const [field3, setfield3] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/api/user/${user?.user_id}`);
      setproducts(response.data.products);
      setuserData(response.data);
      setfield1(response.data.field1);
      setfield2(response.data.field2);
      setfield3(response.data.field3);
      console.log(response.data);
    };
    fetchData();
  }, []);

  // Add Products
  const [showFormModal, setShowFormModal] = useState(false);

  const [newProdName, setnewProdName] = useState("");
  const [newProdField1, setnewProdField1] = useState("");
  const [newProdField2, setnewProdField2] = useState("");
  const [newProdField3, setnewProdField3] = useState("");
  const [newProdpic, setnewProdpic] = useState(null);

  const handleAddProduct = (image) => {
    setShowFormModal(true);
  };

  async function NewProduct(event) {
    event.preventDefault();
    let formField = new FormData();

    formField.append("user", user.user_id);
    formField.append("name", newProdName);
    formField.append("field1Val", newProdField1);
    formField.append("field2Val", newProdField2);
    formField.append("field3Val", newProdField3);
    if (newProdpic !== null) {
      formField.append("photo", newProdpic);
    }
    await axios({
      method: "post",
      url: "/api/products/",
      data: formField,
    })
      .then(function (response) {
        console.log(response);
        setShowFormModal(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        // window.location.reload();
      });
  }

  async function handleField1Change(event) {
    setfield1(event.target.value);
    let formField = new FormData();

    formField.append("field1", event.target.value);

    await axios({
      method: "put",
      url: `/api/user/${user.user_id}/`,
      data: formField,
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        // window.location.reload();
      });
  }

  async function handleField2Change(event) {
    setfield2(event.target.value);
    let formField = new FormData();

    formField.append("field2", event.target.value);

    await axios({
      method: "put",
      url: `/api/user/${user.user_id}/`,
      data: formField,
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        // window.location.reload();
      });
  }

  async function handleField3Change(event) {
    setfield3(event.target.value);
    let formField = new FormData();

    formField.append("field3", event.target.value);

    await axios({
      method: "put",
      url: `/api/user/${user.user_id}/`,
      data: formField,
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        // window.location.reload();
      });
  }

  return (
    <div>
      <Container>
        <table className="productTable">
          <thead>
            <tr className="productTableHead">
              <th style={{ backgroundColor: "#fff !important", width: "10px" }}>
                S. No.
              </th>
              <th style={{ backgroundColor: "#fff !important", width: "30px" }}>
                Product Name
              </th>
              <th style={{ backgroundColor: "#fff !important", width: "30px" }}>
                <Form.Control
                  className="headForm"
                  value={field1}
                  maxLength="500"
                  onChange={(e) => handleField1Change(e)}
                />
              </th>
              <th style={{ backgroundColor: "#fff !important", width: "30px" }}>
                <Form.Control
                  className="headForm"
                  value={field2}
                  maxLength="500"
                  onChange={(e) => handleField2Change(e)}
                />
              </th>
              <th style={{ backgroundColor: "#fff !important", width: "40px" }}>
                <Form.Control
                  className="headForm"
                  value={field3}
                  maxLength="500"
                  onChange={(e) => handleField3Change(e)}
                />
              </th>
              <th style={{ backgroundColor: "#fff !important", width: "60px" }}>
                Product Photo
              </th>
              <th style={{ backgroundColor: "#fff !important", width: "30px" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr
                className="productTableRow"
                key={index}
                style={{ backgroundColor: "#220c49" }}
              >
                <td>{index + 1}</td>
                <td>
                  <Form.Control
                    value={product.name}
                    placeholder={`Name of the Product ${index + 1}`}
                    maxLength="500"
                    onChange={(e) =>
                      handleProductChange(index, "name", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    value={product.field1Val}
                    placeholder={`${userData.field1} ${index + 1}`}
                    maxLength="500"
                    onChange={(e) =>
                      handleProductChange(index, "field1Val", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    value={product.field2Val}
                    placeholder={`${userData.field2} ${index + 1}`}
                    maxLength="500"
                    onChange={(e) =>
                      handleProductChange(index, "field2Val", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    value={product.field3Val}
                    placeholder={`${userData.field3} ${index + 1}`}
                    maxLength="500"
                    onChange={(e) =>
                      handleProductChange(index, "field3Val", e.target.value)
                    }
                  />
                </td>
                <td>
                  <Form.Control
                    name={`productPic${index + 1}`}
                    type="file"
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={(e) =>
                      handleProductPicChange(index, e.target.files[0])
                    }
                  />
                </td>
                <td>
                  <Button
                    className="btn--secondary"
                    onClick={() => EditProduct(product.id)}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteButton(product)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          centered
          show={showFormModal}
          onHide={() => setShowFormModal(false)}
        >
          <Modal.Header
            style={{ backgroundColor: "#220c49", color: "#face1d" }}
            closeButton
            variant="white"
          >
            <Modal.Title>Add Product</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#220c49", color: "#face1d" }}>
            <Form>
              <Form.Group as={Col}>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name="newProdName"
                  value={newProdName}
                  onChange={(e) => setnewProdName(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  name="photo"
                  onChange={(e) => setnewProdpic(e.target.files[0])}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>{userData.field1}</Form.Label>
                <Form.Control
                  name="newProdField1"
                  value={newProdField1}
                  onChange={(e) => setnewProdField1(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{userData.field2}</Form.Label>
                <Form.Control
                  name="newProdField2"
                  value={newProdField2}
                  onChange={(e) => setnewProdField2(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>{userData.field3}</Form.Label>
                <Form.Control
                  name="newProdField3"
                  value={newProdField3}
                  onChange={(e) => setnewProdField3(e.target.value)}
                />
              </Form.Group>

              <Button
                className="btn--outline--dark"
                onClick={(e) => NewProduct(e)}
              >
                Submit
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer
            style={{ backgroundColor: "#220c49", color: "#face1d" }}
          >
            <Button
              className="btn--four"
              onClick={() => setShowFormModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          centered
          show={deleteModalshow}
          onHide={() => setDeleteModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Event?</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <p>
              Are your sure you want to delete the event{" "}
              <strong>{selectedProduct?.name}</strong>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn--secondary"
              onClick={() => handleDelete(selectedProduct?.id)}
            >
              Delete
            </Button>
            <Button className="btn--four" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Row style={{ marginTop: "10px" }}>
          <Col>
            {products.length < 150 ? (
              <Button className="btn--secondary" onClick={handleAddProduct}>
                Add Product
              </Button>
            ) : (
              <>
                <Button className="btn--secondary" disabled onClick={addEvent}>
                  Maximimun 150 Products
                </Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ManageProducts;
