import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../App.css";
import {
  Row,
  Col,
  Container,
  Card,
  ListGroup,
  Alert,
  Toast,
  Image,
  Button,
  FormControl,
  InputGroup,
  Form,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./HomeScreen.css";
import axios from "axios";
import vyapaarLogoW from "../images/vyapaarLogoW.jpg";
import { LinkContainer } from "react-router-bootstrap";

const HomeScreen = () => {
  const user = useSelector((state) => state.authentication.user);

  const navigate = useNavigate();
  const [userData, setUserData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/api/user/${user?.user_id}`);
      setUserData(response.data);
      if (response.data.kyc === "False") {
        setShow(true);
      }
      console.log(response.data);
    };

    fetchData();
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [product, setProduct] = useState("");

  const handleSearch = () => {
    axios
      .get("/api/search/", {
        params: {
          city: city,
          category: category,
          product: product,
        },
      })
      .then((response) => {
        // Handle response data, update state, etc.
        console.log(response.data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  return (
    <div>
      <div className="homeContainer">
        <Container>
          <Modal show={show} onHide={handleClose} dialogClassName="modal-top">
            <Modal.Header closeButton>
              <Modal.Title>Top Popup</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please complete your business Profile</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => navigate("/company-profile")}
              >
                Complete Profile
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Row style={{ margin: "30px 0" }}>
            <Col style={{ textAlign: "center" }}>
              <Image className="vyapaarImage" fluid src={vyapaarLogoW} />
            </Col>
          </Row>
          <Row className="homeBar1">
            <Col style={{ padding: "0" }}>
              <Form.Control
                className="searchField searchLeft"
                type="text"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Col>
            <Col style={{ padding: "0" }}>
              <Form.Select
                className="searchField"
                aria-label="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option>Category</option>
                <option value="Retailer">Retailer</option>
                <option value="Health Practitioner">Health Practitioner</option>
                <option value="Distributor">Distributor</option>
                <option value="Food Service">Food Service</option>
                <option value="Supplier/Raw Ingredient Distributor">
                  Supplier/Raw Ingredient Distributor
                </option>
                <option value="Manufacturer">Manufacturer</option>
                <option value="Business Services">Business Services</option>
                <option value="Investor">Investor</option>
                <option value="Others">Others</option>
                {/* Add more options as needed */}
              </Form.Select>
            </Col>
            <Col style={{ padding: "0" }}>
              <Form.Control
                className="searchField searchRight"
                type="text"
                placeholder="Product"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
              />
            </Col>
            <div style={{ marginTop: "30px" }}>
              <Col style={{ textAlign: "center" }}>
                <Button
                  className="btn--secondary"
                  onClick={() =>
                    navigate(`/search/${city}/${category}/${product}`)
                  }
                >
                  Search
                </Button>
              </Col>
            </div>
          </Row>
        </Container>
        <Row className="homeBar2">
          <Card className="homeCard">
            <Card.Body>
              <h2 className="homeCardT1">10+</h2>
              <h3 className="homeCardT2">Cities</h3>
            </Card.Body>
          </Card>
          <Card className="homeCard">
            <Card.Body>
              <h2 className="homeCardT1">100+</h2>
              <h3 className="homeCardT2">Verified Sellers</h3>
            </Card.Body>
          </Card>
          <Card className="homeCard">
            <Card.Body>
              <h2 className="homeCardT1">100+</h2>
              <h3 className="homeCardT2">Products</h3>
            </Card.Body>
          </Card>
        </Row>
        <Row className="registerRow">
          <Col>
            <Container>
              <div onClick={() => navigate("/register")}>
                <h5 style={{ cursor: "pointer" }}>
                  Register Now <i class="fa-solid fa-arrow-right" />
                </h5>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomeScreen;
