import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  ListGroup,
  Alert,
  Toast,
  Image,
  Button,
  FormControl,
  InputGroup,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./SearchScreen.css";
import image1 from "../images/image1.jpeg";
import vjLogo from "../images/vjLogo.jpg";

function SearchScreen() {
  // const [chairmen, setChairmen] = useState([]);
  const url = useParams();

  const [city, setCity] = useState(url.city);
  const [category, setCategory] = useState(url.category);
  const [product, setProduct] = useState(url.product);

  const [companies, setCompanies] = useState([]);

  const handleSearch = () => {
    axios
      .get("/api/search/user/", {
        params: {
          city: city,
          category: category,
          product: product,
        },
      })
      .then((response) => {
        // Handle response data, update state, etc.
        console.log(response.data);
        setCompanies(response.data);
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  useEffect(() => {
    setCity(url.city);
    setCategory(url.category);
    setProduct(url.product);
  }, []);

  useEffect(() => {
    handleSearch();
  }, [url]);

  const navigate = useNavigate();

  return (
    <div>
      <Container>
        <div>
          <Row>
            <Col style={{ padding: "0" }}>
              <Form.Control
                className="searchField searchLeft"
                type="text"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Col>
            <Col style={{ padding: "0" }}>
              <Form.Select
                className="searchField"
                aria-label="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option>Category</option>
                <option value="Retailer">Retailer</option>
                <option value="Health Practitioner">Health Practitioner</option>
                <option value="Distributor">Distributor</option>
                <option value="Food Service">Food Service</option>
                <option value="Supplier/Raw Ingredient Distributor">
                  Supplier/Raw Ingredient Distributor
                </option>
                <option value="Manufacturer">Manufacturer</option>
                <option value="Business Services">Business Services</option>
                <option value="Investor">Investor</option>
                <option value="Others">Others</option>
                {/* Add more options as needed */}
              </Form.Select>
            </Col>
            <Col style={{ padding: "0" }}>
              <Form.Control
                className="searchField searchRight"
                type="text"
                placeholder="Product"
                value={product}
                onChange={(e) => setProduct(e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "30px" }}>
            <Col style={{ textAlign: "center" }}>
              <Button
                className="btn--secondary"
                onClick={() =>
                  navigate(`/search/?${city}/?${category}/?${product}`)
                }
              >
                Search
              </Button>
            </Col>
          </Row>
        </div>
        <Row>
          {companies.map((company) => (
            <Col
              style={{ margin: "1em 0" }}
              lg={4}
              md={12}
              xs={12}
              key={company.id}
            >
              <Card className="companyInfoCard">
                <Row>
                  <Col
                    xs={4}
                    // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <Image
                      className="companyInfoImage"
                      style={{
                        border: "0",
                        borderRadius: "15px",
                        marginLeft: "10px",
                      }}
                      src={company.logo}
                      alt={company.company_name}
                      fluid
                    />
                  </Col>
                  <Col xs={8}>
                    <Card.Body>
                      <div
                        onClick={() => navigate(`/company/${company.id}`)}
                        style={{ cursor: "pointer" }}
                      >
                        <Card.Title
                          style={{
                            fontSize: "22px",
                            fontWeight: "700",
                            color: "#face1d",
                          }}
                        >
                          {company.companyName}
                        </Card.Title>
                        <Card.Subtitle
                          style={{
                            fontSize: "20px",
                            color: "#face1d",
                            textTransform: "uppercase",
                          }}
                        >
                          {company.product}
                        </Card.Subtitle>
                      </div>
                      <Row style={{ marginTop: "10px" }}>
                        <Card.Subtitle
                          style={{
                            fontSize: "18px",
                            textTransform: "capitalize",
                            color: "#fff",
                            marginBottom: "0",
                          }}
                        >
                          {company.full_name}
                        </Card.Subtitle>
                        <Col>
                          <p
                            style={{ textTransform: "capitalize" }}
                            className="companyInfoText1"
                          >
                            <i class="fa-solid fa-phone"></i>{" "}
                            {company.regionName} | {company.groupName}
                          </p>
                        </Col>
                      </Row>
                      <div>
                        <a href={`tel:${company.contactNumber}`}>
                          <Button
                            style={{ borderRadius: "100px" }}
                            className="btn--four"
                          >
                            <i className="fa-solid fa-phone" />
                          </Button>
                        </a>
                        <a target="_blank" href={`mailto:${company.email}`}>
                          <Button
                            style={{ borderRadius: "100px", margin: "0 5px" }}
                            className="btn--four"
                          >
                            <i className="fa-solid fa-envelope" />
                          </Button>
                        </a>
                        <a
                          target="_blank"
                          href={`https://wa.me/${company.whatsappNumber}/`}
                        >
                          <Button
                            style={{ borderRadius: "100px" }}
                            className="btn--four"
                          >
                            <i className="fa-brands fa-whatsapp" />
                          </Button>
                        </a>
                      </div>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default SearchScreen;
