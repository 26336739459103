import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Register.css";

import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import SecurePageSection from "../SecurePageSection";
// import SecurePageSection from "../../../SecurePageSection";

const Register = () => {
  const user = useSelector((state) => state.authentication.user);

  const [regEmails, setRegEmails] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  const [photo, setphoto] = useState(null);
  const [aadharFront, setaadharFront] = useState(null);
  const [aadharback, setaadharback] = useState(null);

  // useEffect(() => {
  //   const checkFormStatus = async () => {
  //     try {
  //       const response = await axios.get(`/api/form/user/ee/application/${user.user_id}`, {});
  //       console.log(response.data);
  //       if (response.data.isFormFilled === true) {
  //         navigate("/ee/registered/");
  //       }
  //     } catch (error) {
  //       console.error("Error checking form status:", error);
  //     }
  //   };

  //   checkFormStatus();
  // }, []);

  // const [regConvenor, setRegConvenor] = useState("");
  const navigate = useNavigate();

  const fetchRegEmails = async (regionName) => {
    const chairmanResponse = await axios.get(
      `https://jsgif.co.in/api/data/${regionName}/region-ob/emails/`
    );
    const chairmanEmail = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.email);
    setRegEmails(chairmanEmail);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setisSubmitting(true);
    const formField = new FormData();
    // const finalEmail = values.email;
    const finalEmail = values.email;
    formField.append("accessGroup", "vyapari");

    Object.keys(values).forEach((key) => {
      if (!(values[key] instanceof File)) {
        formField.append(key, values[key]);
      }
    });

    formField.append("kyc", "False");
    formField.append("password", values.password);
    console.log(values.password);

    if (photo !== null) {
      formField.append("photo", photo);
    }

    await axios({
      method: "post",
      url: "/api/user-create/",
      data: formField,
    })
      .then(function (response) {
        console.log(response);
        navigate(`/verify/${response.data.email}`);
      })
      .catch(function (error) {
        console.log(error);
      });

    setisSubmitting(false);
  };

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
    navigate("/");
  };

  const [userData, setuserData] = useState("");
  const fetchUserData = async () => {
    await axios({
      method: "get",
      url: `/user/user/${user?.user_id}/`,
    })
      .then(function (response) {
        console.log(response.data);
        setuserData(response.data);
        setInitialValues({
          full_name: response.data.full_name || "",
          email: response.data.email || "",
          dob: response.data.dob || "",
          gender: response.data.gender || "",
          contactNumber: response.data.contactNumber || "",
          whatsappNumber: response.data.whatsappNumber || "",
          regionName: response.data.regionName || "",
          groupName: response.data.groupName || "",
          companyName: response.data.companyName || "",
          dealingIn: response.data.dealingIn || "",
          isJsg: response.data.isJsg ? "Yes" : "No",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // useEffect(() => {
  //   const storedValues = localStorage.getItem("formData");
  //   if (storedValues && storedValues.email === userData.email) {
  //     const parsedStoredValues = JSON.parse(storedValues);
  //     const mergedValues = { ...initialValues, ...parsedStoredValues };
  //     setInitialValues(mergedValues);
  //   }
  // }, [userData]);

  const [initialValues, setInitialValues] = useState({
    full_name: "",
    email: "",
    dob: "",
    gender: "",
    password: "",
    password2: "",
    contactNumber: "",
    whatsappNumber: "",
    regionName: "",
    groupName: "",
    companyName: "",
    category: "",
  });

  const validationSchema = Yup.object({
    full_name: Yup.string().required("Full Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    dob: Yup.date().required("Date of Birth is required"),
    gender: Yup.string(),
    contactNumber: Yup.string().required("Contact Number is required"),
    whatsappNumber: Yup.string().required("Whatsapp Number is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    password2: Yup.string()
      .required("Please re-enter the password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    regionName: Yup.string(),
    groupName: Yup.string(),
    companyName: Yup.string().required("Business Name is required"),
    category: Yup.string().required("Business Category is required"),
  });

  const valuesRef = useRef(null);

  useEffect(() => {
    const valuesFunction = () => {
      console.log(valuesRef.current.values);
    };

    valuesFunction();
  }, [valuesRef]);

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    await axios({
      method: "get",
      url: "https://jsgif.co.in/user/region/",
    })
      .then(function (response) {
        setRegions(
          response.data.sort((a, b) => a.region.localeCompare(b.region))
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async (regionName) => {
    try {
      const response = await axios.get(
        `https://jsgif.co.in/user/${regionName}/groups/`
      );
      const sortedGroups = response.data.sort((a, b) =>
        a.group.localeCompare(b.group)
      );
      setGroups(sortedGroups);
    } catch (error) {
      // Handle errors if needed
    }
  };

  return (
    <div className="register">
      {/* <SecurePageSection accessableUsers={"admin,strotUser"} /> */}

      <div className="formContainer">
        <div className="registerForm">
          <h1>Registration</h1>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={valuesRef}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        name="full_name"
                        value={values.full_name}
                        placeholder="Full Name"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        isInvalid={!!errors.full_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.full_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        placeholder="Email Address"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control
                        type="date"
                        name="dob"
                        value={values.dob}
                        placeholder="Email Address"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        isInvalid={!!errors.dob}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dob}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Gender</Form.Label>
                      <Form.Select
                        name="gender"
                        value={values.gender}
                        onChange={handleChange}
                        isInvalid={!!errors.gender}
                      >
                        <option>Select Your Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.gender}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        name="contactNumber"
                        value={values.contactNumber}
                        placeholder="Mobile Number"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        isInvalid={!!errors.contactNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.contactNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Whatsapp Number</Form.Label>
                      <Form.Control
                        name="whatsappNumber"
                        value={values.whatsappNumber}
                        placeholder="Whatsapp Number"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        isInvalid={!!errors.whatsappNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.whatsappNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        placeholder="Minimum 8 Characters"
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={6}>
                    <Form.Group>
                      <Form.Label>Re-type Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password2"
                        value={values.password2}
                        placeholder="Minimum 8 Characters"
                        onChange={handleChange}
                        isInvalid={!!errors.password2}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password2}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginTop: "20px" }}>
                  <Col xs={12} md={4}>
                    <Form.Group>
                      <Form.Label>Are you a JSG Member?</Form.Label>
                      <Form.Select
                        name="isJsg"
                        checked={values.isJsg}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option>Selece one option</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  {values.isJsg === "true" ? (
                    <>
                      <Col xs={12} md={4}>
                        <Form.Group>
                          <Form.Label>Region Name</Form.Label>
                          <Form.Select
                            name="regionName"
                            value={values.regionName}
                            onChange={(e) => {
                              handleChange(e);
                              fetchGroups(e.target.value);
                            }}
                            isInvalid={!!errors.regionName}
                          >
                            <option>Select your Region</option>
                            {regions.map((region, index) => (
                              <option
                                key={index}
                                style={{ textTransform: "capitalize" }}
                                value={region.region.toLowerCase()}
                              >
                                {region.region.charAt(0).toUpperCase() +
                                  region.region.slice(1).toLowerCase()}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.regionName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={4}>
                        <Form.Group>
                          <Form.Label>Group Name</Form.Label>
                          <Form.Select
                            name="groupName"
                            value={values.groupName}
                            onChange={handleChange}
                            isInvalid={!!errors.groupName}
                          >
                            <option>Select your Group</option>
                            {groups.map((group, index) => (
                              <option
                                key={index}
                                style={{ textTransform: "capitalize" }}
                                value={group.group.toLowerCase()}
                              >
                                {group.group.charAt(0).toUpperCase() +
                                  group.group.slice(1).toLowerCase()}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.groupName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={12} md={4}>
                        <Form.Group>
                          <Form.Label>Region Name</Form.Label>
                          <Form.Select
                            name="regionName"
                            value={values.regionName}
                            onChange={handleChange}
                            disabled
                          >
                            <option>Select your Region</option>
                            {regions.map((region, index) => (
                              <option
                                key={index}
                                style={{ textTransform: "capitalize" }}
                                value={region.region.toLowerCase()}
                              >
                                {region.region.charAt(0).toUpperCase() +
                                  region.region.slice(1).toLowerCase()}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={4}>
                        <Form.Group>
                          <Form.Label>Group Name</Form.Label>
                          <Form.Select
                            name="groupName"
                            value={values.groupName}
                            onChange={handleChange}
                            disabled
                          >
                            <option>Select your Group</option>
                            {groups.map((group, index) => (
                              <option
                                key={index}
                                style={{ textTransform: "capitalize" }}
                                value={group.group.toLowerCase()}
                              >
                                {group.group.charAt(0).toUpperCase() +
                                  group.group.slice(1).toLowerCase()}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Row>

                <Row className="mb-3">
                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Name of the business</Form.Label>
                      <Form.Control
                        name="companyName"
                        value={values.companyName}
                        placeholder="Name of your Business"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        isInvalid={!!errors.companyName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.companyName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col lg={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Category</Form.Label>
                      <Form.Select
                        name="category"
                        value={values.category}
                        onChange={handleChange}
                        isInvalid={!!errors.category}
                      >
                        <option>Select Your Category</option>
                        <option value="Retailer">Retailer</option>
                        <option value="Health Practitioner">
                          Health Practitioner
                        </option>
                        <option value="Distributor">Distributor</option>
                        <option value="Food Service">Food Service</option>
                        <option value="Supplier/Raw Ingredient Distributor">
                          Supplier/Raw Ingredient Distributor
                        </option>
                        <option value="Manufacturer">Manufacturer</option>
                        <option value="Business Services">
                          Business Services
                        </option>
                        <option value="Investor">Investor</option>
                        <option value="Others">Others</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.category}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12}>
                    <Button
                      className="btn--outline--dark"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      {isSubmitting ? "Submitting..." : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>

        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Form Submitted </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <p>
              Your Form Has successfully been submitted. Please check your email
              for confirmation.
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button className="btn--four" onClick={handleModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Register;
