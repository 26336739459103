import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import amish from "../images/AmishDoshi.jpg";
import akshay from "../images/AkshayJain.jpg";
import dd from "../images/DivyadityaKothari.jpg";

import "./AboutVyapaar.css";

function AboutVyapaar() {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h2 style={{ textAlign: "center" }}>About Vyapaar</h2>
            <p style={{ textAlign: "justify", fontSize: "18px" }}>
              Welcome to Vyapaar, your premier destination for seamless business
              networking and connections. Powered by Strot, a venture of JSGIF,
              Vyapaar is revolutionizing the way businesses connect and
              collaborate in today's digital age.
            </p>
          </Col>
          <Col>
            <h2 style={{ textAlign: "center" }}>Our Mission</h2>
            <p style={{ textAlign: "justify", fontSize: "18px" }}>
              At Vyapaar, our mission is simple: to empower businesses of all
              sizes to thrive through meaningful connections and partnerships.
              We understand the importance of networking in today's competitive
              landscape, and we're here to provide you with the tools and
              resources you need to expand your network, explore new
              opportunities, and drive growth.
            </p>
          </Col>
        </Row>
        <Row>
          <h2 style={{ textAlign: "center" }}>Our thinkers</h2>
          <Col
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={amish}
              style={{
                height: "300px",
                borderRadius: "20px",
                marginBottom: "20px",
              }}
            />
            <h4>Amish K Doshi</h4>
            <h5>JSGIF President</h5>
          </Col>
          <Col
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={akshay}
              style={{
                height: "300px",
                borderRadius: "20px",
                marginBottom: "20px",
              }}
            />
            <h4>Akshay Jain</h4>
            <h5>JSGIF Strot Chairman</h5>
          </Col>
          <Col
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={dd}
              style={{
                height: "300px",
                borderRadius: "20px",
                marginBottom: "20px",
              }}
            />
            <h4>Divyaditya Kothari</h4>
            <h5>JSGIF Ask JSG Chairman</h5>
          </Col>
        </Row>
        <div>
          <h2>Key Features</h2>
          <Row>
            <Col md={6}>
              <div className="featuresCard">
                <h3>Networking Made Easy</h3>
                <p>
                  Discover and connect with a diverse community of businesses,
                  spanning various sectors and regions.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="featuresCard">
                <h3>Profile Customization</h3>
                <p>
                  Showcase your company's strengths, services, and goals through
                  personalized profiles, attracting relevant opportunities and
                  collaborations.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="featuresCard">
                <h3>Targeted Search</h3>
                <p>
                  Utilize advanced search filters to find businesses that align
                  with your specific needs and objectives.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="featuresCard">
                <h3>Interactive Communication</h3>
                <p>
                  Engage in meaningful conversations through messaging and
                  networking events, fostering valuable connections in
                  real-time.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="featuresCard">
                <h3>Resource Sharing</h3>
                <p>
                  Access a wealth of industry insights, best practices, and
                  resources to stay informed and inspired in your business
                  endeavors.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default AboutVyapaar;
