import React, { useState } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import "./ForgotPassword.css";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [password1, setpassword1] = useState("");
  const [password2, setpassword2] = useState("");

  const navigate = useNavigate();

  const [otpID, setOtpID] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setMessage("Sending OTP, Please Wait");
    axios
      .post("/api/forgot-password/", { email })
      .then((response) => {
        setOtpSent(true);
        console.log(response);
        setOtpID(response.data.id);
        setMessage("");
      })
      .catch((error) => {
        console.log(error);
        setMessage("Error: User not found");
      });
  };

  const verifyOtp = (event) => {
    event.preventDefault();
    axios
      .post("/api/verify-otp/", { email, otp })
      .then((response) => {
        setMessage(
          "OTP verified successfully! You can reset your password now."
        );
        setOtp("");
        setOtpVerified(true);
      })
      .catch((error) => {
        console.log(error);
        setMessage("Error: Invalid OTP");
      });
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    if (password1 !== password2) {
      setMessage("Error: Passwords do not match");
      return;
    }

    axios
      .put("/api/password-change/", { email, password: password1 })
      .then((response) => {
        setMessage(
          "Password changed successfully! You can now log in with your new password."
        );
        setpassword1("");
        setpassword2("");
        navigate("/");
      })
      .catch((error) => {
        setMessage("Error: Unable to change password. Please try again later.");
      });
  };

  return (
    <div className="forgotPass">
      {otpVerified ? (
        <div className="forgotPassFormConatiner">
          <div className="forgotPassBox">
            <h2>Reset Passworrd</h2>
            <p>{message}</p>
            <Form onSubmit={handleSubmit}>
              <Form.Label htmlFor="email">New Password</Form.Label>
              <Form.Control
                type="password"
                id="password1"
                value={password1}
                onChange={(e) => setpassword1(e.target.value)}
                required
              />
              <Form.Label htmlFor="otp">Re-Type the New Password</Form.Label>
              <Form.Control
                id="password2"
                type="password"
                value={password2}
                onChange={(e) => setpassword2(e.target.value)}
                required
              />
              <Button className="btn--secondary" onClick={handlePasswordChange}>
                Change Password
              </Button>
            </Form>
          </div>
        </div>
      ) : (
        <div className="forgotPassFormConatiner">
          <div className="forgotPassBox">
            <h2>Forgot Password?</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Label htmlFor="email">Enter your email:</Form.Label>
              <Form.Control
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {otpSent && (
                <>
                  <Form.Label htmlFor="otp">Enter OTP</Form.Label>
                  <Form.Control
                    id="otp"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </>
              )}
              <p>{message}</p>
              {otpSent ? null : (
                <Button className="btn--secondary" type="submit">
                  {" "}
                  Get OTP
                </Button>
              )}
              {otpSent && (
                <Button className="btn--secondary" onClick={verifyOtp}>
                  Verify
                </Button>
              )}
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
