import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
  Modal,
  Table,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Register.css";

import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import SecurePageSection from "../SecurePageSection";

const CompanyProfile = () => {
  const user = useSelector((state) => state.authentication.user);

  const [regEmails, setRegEmails] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  const [logo, setLogo] = useState(null);
  const [pdf, setPdf] = useState(null);

  // const [regConvenor, setRegConvenor] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    setisSubmitting(true);
    const formField = new FormData();
    const finalEmail = values.email;

    Object.keys(values).forEach((key) => {
      if (!(values[key] instanceof File) && key !== "password") {
        formField.append(key, values[key]);
      }
    });

    formField.append("kyc", "True");
    formField.append("is_active", true);

    if (logo !== null) {
      formField.append("logo", logo);
    }

    if (pdf !== null) {
      formField.append("pdf", pdf);
    }

    await axios({
      method: "put",
      url: `/api/user/${user?.user_id}/`,
      data: formField,
    })
      .then(function (response) {
        console.log(response.data);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });

    setisSubmitting(false);
  };

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
    navigate("/");
  };

  const fetchUserData = async () => {
    await axios({
      method: "get",
      url: `/api/user/${user?.user_id}`,
    })
      .then(function (response) {
        console.log(response.data);
        setInitialValues({
          accessGroup: response.data.accessGroup || "",
          full_name: response.data.full_name || "",
          email: response.data.email || "",
          dob: response.data.dob || "",
          gender: response.data.gender || "",
          contactNumber: response.data.contactNumber || "",
          whatsappNumber: response.data.whatsappNumber || "",
          regionName: response.data.regionName || "",
          groupName: response.data.groupName || "",
          companyName: response.data.companyName || "",
          kyc: response.data.kyc || "",
          category: response.data.category || "",
          aboutCompany: response.data.aboutCompany || "",
          gstNum: response.data.gstNum || "",
          companyAddress: response.data.companyAddress || "",
          city: response.data.city || "",
          msme: response.data.msme ? "Yes" : "No",
          iso: response.data.iso ? "Yes" : "No",
          udhyogNum: response.data.udhyogNum || "",
          field1: response.data.field1 || "",
          field2: response.data.field2 || "",
          field3: response.data.field3 || "",
          user: response.data.user || "",
          products: response.data.products || "",
          isJsg: response.data.isJsg ? "Yes" : "No",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(user);
    fetchUserData();
  }, []);

  const [initialValues, setInitialValues] = useState({
    accessGroup: "",
    full_name: "",
    email: "",
    dob: "",
    gender: "",
    contactNumber: "",
    whatsappNumber: "",
    regionName: "",
    groupName: "",
    companyName: "",
    kyc: "",
    category: "",
    aboutCompany: "",
    gstNum: "",
    companyAddress: "",
    city: "",
    msme: "",
    iso: "",
    udhyogNum: "",
    field1: "",
    field2: "",
    field3: "",
    pdf: null,
    logo: null,
  });

  const validationSchema = Yup.object({
    full_name: Yup.string(),
    email: Yup.string(),
    dob: Yup.string(),
    gender: Yup.string(),
    contactNumber: Yup.string(),
    whatsappNumber: Yup.string(),
    regionName: Yup.string(),
    groupName: Yup.string(),
    companyName: Yup.string(),
    kyc: Yup.string(),
    category: Yup.string(),
    aboutCompany: Yup.string(),
    gstNum: Yup.string(),
    companyAddress: Yup.string(),
    city: Yup.string(),
    msme: Yup.string(),
    iso: Yup.string(),
    udhyogNum: Yup.string(),
    field1: Yup.string(),
    field2: Yup.string(),
    field3: Yup.string(),
    logo: Yup.mixed().nullable(),
    pdf: Yup.mixed().nullable(),
  });

  const valuesRef = useRef(null);

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    await axios({
      method: "get",
      url: "https://jsgif.co.in/user/region/",
    })
      .then(function (response) {
        setRegions(
          response.data.sort((a, b) => a.region.localeCompare(b.region))
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    fetchGroups(initialValues.regionName);
  }, [initialValues]);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async (regName) => {
    try {
      const response = await axios.get(
        `https://jsgif.co.in/user/${regName}/groups/`
      );
      const sortedGroups = response.data.sort((a, b) =>
        a.group.localeCompare(b.group)
      );
      setGroups(sortedGroups);
    } catch (error) {
      // Handle errors if needed
    }
  };

  return (
    <div className="register">
      {/* <SecurePageSection accessableUsers={"admin,strotUser"} /> */}

      <div className="formContainer">
        <Row>
          <Col>
            <h1>User Profile</h1>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              className="btn--primary"
              onClick={() => navigate("/manage-products")}
            >
              Manage Products
            </Button>
          </Col>
        </Row>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={valuesRef}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      name="full_name"
                      value={values.full_name}
                      placeholder="Full Name"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.full_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.full_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      name="email"
                      value={values.email}
                      placeholder="Email Address"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      name="dob"
                      value={values.dob}
                      placeholder="Email Address"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.dob}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dob}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Gender</Form.Label>
                    <Form.Select
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      isInvalid={!!errors.gender}
                    >
                      <option>Select Your Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.gender}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      name="contactNumber"
                      value={values.contactNumber}
                      placeholder="Mobile Number"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.contactNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.contactNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Whatsapp Number</Form.Label>
                    <Form.Control
                      name="whatsappNumber"
                      value={values.whatsappNumber}
                      placeholder="Whatsapp Number"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.whatsappNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.whatsappNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginTop: "20px" }}>
                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>Region Name</Form.Label>
                    <Form.Select
                      name="regionName"
                      value={values.regionName}
                      onChange={(e) => {
                        handleChange(e);
                        fetchGroups(e.target.value);
                      }}
                      isInvalid={!!errors.regionName}
                    >
                      <option>Select your Region</option>
                      {regions.map((region, index) => (
                        <option
                          key={index}
                          style={{ textTransform: "capitalize" }}
                          value={region.region.toLowerCase()}
                        >
                          {region.region.charAt(0).toUpperCase() +
                            region.region.slice(1).toLowerCase()}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.regionName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs={12} md={6}>
                  <Form.Group>
                    <Form.Label>Group Name</Form.Label>
                    <Form.Select
                      name="groupName"
                      value={values.groupName}
                      onChange={handleChange}
                      isInvalid={!!errors.groupName}
                    >
                      <option>Select your Group</option>
                      {groups.map((group, index) => (
                        <option
                          key={index}
                          style={{ textTransform: "capitalize" }}
                          value={group.group.toLowerCase()}
                        >
                          {group.group.charAt(0).toUpperCase() +
                            group.group.slice(1).toLowerCase()}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.groupName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <h1 style={{ marginTop: "2rem" }}>Company Profile</h1>
              <Row className="mb-3">
                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Name of the business</Form.Label>
                    <Form.Control
                      name="companyName"
                      value={values.companyName}
                      placeholder="Name of your Business"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.companyName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Select
                      name="category"
                      value={values.category}
                      onChange={handleChange}
                      isInvalid={!!errors.category}
                    >
                      <option>Select Your Category</option>
                      <option value="Retailer">Retailer</option>
                      <option value="Health Practitioner">
                        Health Practitioner
                      </option>
                      <option value="Distributor">Distributor</option>
                      <option value="Food Service">Food Service</option>
                      <option value="Supplier/Raw Ingredient Distributor">
                        Supplier/Raw Ingredient Distributor
                      </option>
                      <option value="Manufacturer">Manufacturer</option>
                      <option value="Business Services">
                        Business Services
                      </option>
                      <option value="Investor">Investor</option>
                      <option value="Others">Others</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.category}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={12} xs={12}>
                  <Form.Group>
                    <Form.Label>About Company</Form.Label>
                    <Form.Control
                      name="aboutCompany"
                      value={values.aboutCompany}
                      placeholder="About Company"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.aboutCompany}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.aboutCompany}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Company Address</Form.Label>
                    <Form.Control
                      name="companyAddress"
                      value={values.companyAddress}
                      placeholder="Company Address"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.companyAddress}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.companyAddress}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      name="city"
                      value={values.city}
                      placeholder="City"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Company Logo</Form.Label>
                    <Form.Control
                      name="logo"
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      onChange={(e) => {
                        setLogo(e.target.files[0]);
                        handleChange(e);
                      }}
                      isInvalid={!!errors.logo}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.logo}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>ISO Registered</Form.Label>
                    <Form.Select
                      name="iso"
                      value={values.iso}
                      onChange={handleChange}
                      isInvalid={!!errors.iso}
                    >
                      <option>Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.iso}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>MSME Registered</Form.Label>
                    <Form.Select
                      name="msme"
                      value={values.msme}
                      onChange={handleChange}
                      isInvalid={!!errors.msme}
                    >
                      <option>Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.msme}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>GST Number</Form.Label>
                    <Form.Control
                      name="gstNum"
                      value={values.gstNum}
                      placeholder="GST Number"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.gstNum}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.gstNum}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Udhyog Number</Form.Label>
                    <Form.Control
                      name="udhyogNum"
                      value={values.udhyogNum}
                      placeholder="MSME Udhyog Number"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      isInvalid={!!errors.udhyogNum}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.udhyogNum}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Button
                    className="btn--outline--dark"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>

      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Form Submitted </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <p>
            Your Form Has successfully been submitted. Please check your email
            for confirmation.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn--four" onClick={handleModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompanyProfile;
