import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import ResendVerificationEmail from "./ResendVerificationEmail";
import axios from "axios";

const EmailVerification = () => {
  const { token } = useParams(); // Retrieve the token from the URL

  const [status, setstatus] = useState("verifying");
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/api/verify-email/${token}/`)
      .then((response) => {
        if (response.data.message === "Verification successful.") {
          setstatus("verified");
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        } else if (
          response.data.message === "Verification link has expired." ||
          response.data.message === "Verification link not found."
        ) {
          setstatus("failed");
        } else if (response.data.message === "User is already verified.") {
          setstatus("alreadyVerified");
        }
      })
      .catch((error) => {
        console.log("An error occurred:", error);
      });
  }, [token]);

  return (
    <div
      style={{
        paddingTop: "15vh",
        height: "80vh",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {(() => {
        if (status === "verifying") {
          return (
            <>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <h2>Please wait while we are verifing your email!</h2>
            </>
          );
        } else if (status === "verified") {
          return (
            <h2>
              Email Verification Successful, Redirecting you to the login page
            </h2>
          );
        } else if (status === "failed") {
          return (
            <>
              <h2>
                If you see this error frequently then kindly contact +91
                6369769821
              </h2>
            </>
          );
        } else if (status === "alreadyVerified") {
          return (
            <>
              <h2>
                We have already verified your email id. Please the button below
                to sign in.
              </h2>
              <Button
                className="btn--secondary"
                onClick={() => navigate("/login")}
              >
                Sign In
              </Button>
            </>
          );
        }
      })()}
    </div>
  );
};

export default EmailVerification;
