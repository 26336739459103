import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./screens/Layout";
import HomeScreen from "./screens/HomeScreen";
import Register from "./screens/Register";
import Login from "./screens/Login";
import ForgotPassword from "./screens/ForgotPassword";
import SearchScreen from "./screens/SearchScreen";
import BusinessPage from "./screens/BusinessPage";
import AboutVyapaar from "./screens/AboutVyapaar";
import CompanyProfile from "./screens/CompanyProfile";
import ManageProducts from "./screens/ManageProducts";
import ResendVerificationEmail from "./screens/ResendVerificationEmail";
import EmailVerification from "./screens/EmailVerification";
import ChangePassword from "./screens/ChangePassword";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} exact>
            <Route path="" element={<HomeScreen />} />
            <Route path="register" element={<Register />} />
            <Route path="company-profile" element={<CompanyProfile />} />
            <Route path="about-vyapaar" element={<AboutVyapaar />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="verify-email/:token" element={<EmailVerification />} />

            <Route path="change-password" element={<ChangePassword />} />
            <Route
              path="resend-email-verification/:email"
              element={<ResendVerificationEmail />}
            />
            <Route path="company/:id" element={<BusinessPage />} />
            <Route path="manage-products" element={<ManageProducts />} />
            <Route
              path="search/:city?/:category?/:product?"
              element={<SearchScreen />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
